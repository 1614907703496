import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "./auth_service";
import { config } from "./config";

const instance = axios.create(
  {
    // baseURL: "http://localhost:8081/api",
    baseURL: config.apiUrl,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
    },
  }
  // console.log(config.apiUrl)
);
// instance.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     console.log(response.data);
//     return Promise.resolve(response);
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     // console.log(error.response);
//     return Promise.reject(error.response.data);
//   }
// );
export default {
  getData: async (url, parm) => {
    let response;
    await instance({
      method: "GET",
      url: url,
      params: parm,
      headers: { "x-access-token": getToken() },
    })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        setError(err);
        response = { data: null };
      });
    return response;
  },

  postData: async (url, data, extraHeaders, parm) => {
    let response;
    await instance({
      method: "POST",
      headers: { "x-access-token": getToken(), extraHeaders },
      url: url,
      data: data,
      params: parm,
    })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        setError(err);
        response = { data: null };
      });
    return response;
  },

  putData: async (url, parm, data, extraHeaders) => {
    let response;
    await instance({
      method: "PUT",
      headers: { "x-access-token": getToken(), extraHeaders },
      url: url,
      params: parm,
      data: data,
    })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        setError(err);
        response = { data: null };
      });
    return response;
  },

  deleteData: async (url, parm, extraHeaders) => {
    let response;
    await instance({
      method: "DELETE",
      headers: { "x-access-token": getToken(), extraHeaders },
      url: url,
      params: parm,
    })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        setError(err);
        response = { data: null };
      });
    return response;
  },
};

function setError(error) {
  if (!error.response) {
    // network error
    toast.error("Network Error");
  } else {
    if (error.response.data) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.response.statusText);
    }
    if (error.response.status === 403) {
      window.location = `${process.env.PUBLIC_URL}/admin/login`;
    }
  }
}
