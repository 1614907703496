import "../assets/css/loader.css";
import loadingGif from "../assets/images/pre_loader.gif";

import React from "react";

const Loader = () => {
  return (
    <>
      <div className="cls-loader-background">
        <img src={loadingGif} className="clas-loading-item" />
      </div>
    </>
  );
};

export default Loader;
