import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import { getCurrentUser } from "./common/auth_service";
import Loader from "./common/loader";

const Home = React.lazy(() => import("./pages/Home"));
const Service = React.lazy(() => import("./pages/Service"));
const SingleService = React.lazy(() => import("./pages/SingleService"));
const Testimonial = React.lazy(() => import("./pages/Testimonials"));
const Contact = React.lazy(() => import("./pages/Contact"));
const SingleBlog = React.lazy(() => import("./pages/SingleBlog"));
const Blog = React.lazy(() => import("./pages/Blog"));
const About = React.lazy(() => import("./pages/About"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Login = React.lazy(() => import("./pages/Login"));
const BlogUpload = React.lazy(() => import("./pages/Admin/BlogUpload"));
const TestimonialUpload = React.lazy(() =>
  import("./pages/Admin/TestimonialUpload")
);
const AdminDashboard = React.lazy(() => import("./pages/Admin/AdminDashboard"));
const AddTestimonial = React.lazy(() => import("./pages/Admin/AddTestimonial"));
const EditTestimonial = React.lazy(() =>
  import("./pages/Admin/EditTestimonial")
);
const AddBlog = React.lazy(() => import("./pages/Admin/AddBlog"));
const EditBlog = React.lazy(() => import("./pages/Admin/EditBlog"));
const ChangePassword = React.lazy(() => import("./pages/Admin/ChangePassword"));

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Suspense
        fallback={
          <span>
            <Loader />
          </span>
        }
      >
        <Router>
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/"
                // name="Login Page"
                render={(props) => <Home {...props} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/about"}`}
                render={(props) => <About {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/service"}`}
                render={(props) => <Service {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/testimonial"}`}
                render={(props) => <Testimonial {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/service/:serviceIDD"}`}
                render={(props) => <SingleService {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/blog_details/:blogID"}`}
                render={(props) => <SingleBlog {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/contact"}`}
                render={(props) => <Contact {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/blog"}`}
                render={(props) => <Blog {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/login"}`}
                render={(props) => <Login {...props} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return (
                      <Redirect to="/admin/manage_testimonial" {...props} />
                    );
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/manage_testimonial"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <TestimonialUpload {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/manage_blog"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <BlogUpload {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/add_testimonial"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <AddTestimonial {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/edit_testimonial/"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <EditTestimonial {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/add_blog"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <AddBlog {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/edit_blog/"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <EditBlog {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL + "/admin/change_password"}`}
                render={(props) => {
                  if (getCurrentUser()) {
                    return <ChangePassword {...props} />;
                  } else {
                    return <Redirect to="/admin/login" {...props} />;
                  }
                }}
              />

              <Route exact render={(props) => <NotFound {...props} />} />
            </Switch>
          </ScrollToTop>
        </Router>
      </React.Suspense>
    </>
  );
}

export default App;
