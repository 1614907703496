import { apiConfig } from "./api_config";
import ApiService from "./api_service";
import jwtDecode from "jwt-decode";

export async function login(postObject) {
  let { data } = await ApiService.getData(
    apiConfig.Api.user + "login",
    postObject
  );

  if (data) {
    localStorage.setItem("vishrava-token", data.token);
    localStorage.setItem("user", JSON.stringify(data.details));
    // localStorage.setItem("imageUrl", data.details.imageUrl);

    // localStorage.removeItem("contact");
    return true;
  }
  return null;
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("vishrava-token");
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}
export function getToken() {
  try {
    return localStorage.getItem("vishrava-token");
  } catch (error) {
    return null;
  }
}

export function logout() {
  try {
    // return localStorage.getItem("token");
    localStorage.removeItem("vishrava-token");
    localStorage.removeItem("user");
    return true;
  } catch (error) {
    return false;
  }
}
